import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath, router, buildNumber } from '../router';

import { signOut } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/drawer/drawer.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';
import '@shoelace-style/shoelace/dist/components/switch/switch.js';
import 'https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.12.0/cdn/components/animation/animation.js';
import SlDrawer from '@shoelace-style/shoelace/dist/components/drawer/drawer.js';
import SlSwitch from '@shoelace-style/shoelace/dist/components/switch/switch.js';
import '@shoelace-style/shoelace/dist/components/badge/badge.js';
import  '../shared-functions';
import { generateClient } from 'aws-amplify/api';
import * as queries from '../graphql/queries';

@customElement('app-header')
export class AppHeader extends LitElement {
  @property({ type: String }) title = 'letssea.me';
  @property({type: Boolean}) menuOpen: boolean = false;
  @property({ type: Boolean}) enableBack: boolean = false;
  @property({ type: Boolean}) loggedIn = false;

  constructor(){
    super();
    this.currentAuthenticatedUser(); //TODO this is a hack until I can get the router to check to see if user is logged in.
    this.menuOpen = false;
    this.loggedIn = false;

  }

  async firstUpdated() {
    // Give the browser a chance to paint
    await new Promise((r) => setTimeout(r, 1));
    await new Promise(resolve => setTimeout(resolve, 1000));
    const drawer = this.shadowRoot?.querySelector('.drawer-placement-start') as SlDrawer
    const openButton = drawer!.nextElementSibling; //TODO drawer is sometimes null
    const closeButton = this.shadowRoot!.querySelector('sl-button[variant="primary"]');
    openButton!.addEventListener('click', () => drawer.show());
    closeButton!.addEventListener('click', () => drawer.hide());
  }
  static styles = css`
    header {
      z-index: 2000;
      width:100%;
      display: flex;
      flex-direction:row;
      justify-content: space-between;
      align-items: center;
     /* background: var(--app-color-primary);*/
     background: 	white;
      color: white;
      height: 4em;
      padding-left: 5px;
      padding-top: 0px;


      position: fixed;
      left: env(titlebar-area-x, 0);
      /*top: env(titlebar-area-y, 0); */
      top: calc(100dvh - 7dvh);

      height: env(titlebar-area-height, 7dvh);

      width: env(titlebar-area-width, 100%);
      -webkit-app-region: drag;
    }
    .drawer-placement-start{
     font-family: "DM Sans";
     font-size: 16px;
      z-index:2000;
    }





    header h1 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: bold;
    }

    nav a {
      margin-left: 10px;
    }


    #menu {
      margin-right: 20px;
    }
    #back-button-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 12em;
    }

    .nav-brand{
      margin-right:5px;
    }
    @media(prefers-color-scheme: light) {
      header {
       /* color: black;*/
      }

      nav a {
        color: initial;
      }
    }

    sl-drawer::part(panel){
      background-color:white;
    }
    sl-drawer::part(body){
      background-color:grey;
    }
    sl-drawer::part(title){
      background-color:grey;
      font-family: "DM Sans";
    }
    sl-drawer::part(overlay)
    {
     /* background-color:white;*/
    }
    sl-drawer::part(header-actions){
      background-color:whitesmoke;
    }

    sl-button::part(base){
      background-color:#1E2952;
      border-color:black;
      font-size: var(--footer-button-font-size);
      font-family: "DM Sans";
    }
    sl-button::part(label){

      color:white;

    }

    sl-badge::part(base){
      background-color:red;
      width: 35px;
      height: 35px;
      color: white;
    }

  `;

  render() {

    let adminChecked='false';

    if (globalThis.globalIsAdminUser){
        adminChecked = "true";
    }

    let pulseNumber = localStorage.getItem('incomingSwimmers');
    return this.loggedIn ? html`


      <header>

      <div class='nav-brand'>
      <a href="${resolveRouterPath('grid')}" >


     <!-- <sl-animation name="heartBeat" duration="2000" play> -->

     <sl-button variant="default" size="medium" circle>
     <sl-icon name="grid"></sl-icon>
     </sl-button>



     <!-- <img src='https://dg-lb-assets.s3.amazonaws.com/lbicon.png' alt='Official logo' width='55px' height='55px'> -->
     <!-- </sl-animation> -->
      </a>
     </div>

     <div class='nav-notifications'>
      <a href="${resolveRouterPath('notifications')}" >
        <!--
          <sl-button variant="default" size="medium" circle>
                <sl-icon name="bell"></sl-icon>
          </sl-button>
        -->
        <sl-button pill class="menu-button">
        Admirers
            <sl-badge variant="primary" pill pulse>${pulseNumber}</sl-badge>
        </sl-button>
      </a>
      </div>




     <!-- <sl-button size="small" pill href="${resolveRouterPath('')}" variant="primary">Home</sl-button> -->
     <!-- <sl-button size="small" pill href="${resolveRouterPath('profile')}" variant="primary">Profile</sl-button>
      <sl-button size="small" pill href="${resolveRouterPath('preferences')}" variant="primary">Preferences</sl-button>
      <sl-button size="small" pill href="${resolveRouterPath('results')}" variant="primary">Suggested Matches</sl-button>
      <sl-button size="small" pill href="${resolveRouterPath('dates')}" variant="primary">Dates</sl-button>

      Moved all of these buttons to the Drawer so that the header is not so cluttered-->

        <!--
        <div id="back-button-block">
          ${this.enableBack ? html`<sl-button href="${resolveRouterPath()}">
            Back
          </sl-button>` : null}

          <h1>${this.title}</h1>
        </div>
        -->
        <!--
        <sl-drawer label="Drawer" class="drawer-overview" open="false" onSlAfterHide={() => setOpen(false)}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          <SlButton slot="footer" variant="primary" onClick={() => setOpen(false)}>
          Close
          </sl-button>
        </sl-drawer>
        -->
        <sl-drawer id = "drawer" label="Table of Contents" placement="bottom" class="drawer-placement-start" style="--size: 75vh;" >
        You can use these buttons to quickly move to different pages.
        </br>
        </br>
        <!--
        <sl-button size="large" pill href="${resolveRouterPath('about')}" variant="primary">About Us</sl-button>
        <br>
        -->
        <br>

        <sl-button size="large" pill href="${resolveRouterPath('profile')}" variant="primary">My Profile</sl-button>
        </br>
        </br>
        <!-- since we are going w/ the grid, commenting this button out
        <sl-button size="large" pill href="${resolveRouterPath('results')}" variant="primary">Suggested Matches</sl-button>
        </br>
        </br>
       -->

        <sl-button size="large" pill href="${resolveRouterPath('grid')}" variant="primary">Matches Grid</sl-button>
        <br>
        </br>

        <sl-button size="large" pill href="${resolveRouterPath('dates')}" variant="primary">Our Advertisers</sl-button>
        <br>
        </br>
        <sl-button size="large" pill href="${resolveRouterPath('dateideas')}" variant="primary">Date Ideas</sl-button>
        </br>
        </br>
        <!-- removed and consolidated to profile page
        <sl-button size="large" pill href="${resolveRouterPath('biography')}" variant="primary">Biography</sl-button>
        </br>
        </br>
        <sl-button size="large" pill href="${resolveRouterPath('preferences')}" variant="primary">Preferences</sl-button>
        </br>
        </br>
        -->

        <sl-button size="large" pill href="${resolveRouterPath('predates')}" variant="primary">Date Prep</sl-button>
        </br>
        </br>

        <sl-button size="large" pill href="${resolveRouterPath('mychats')}" variant="primary">My Chats</sl-button>
        </br>
        </br>
        <sl-button size="large" pill href="${resolveRouterPath('login')}" variant="primary">Login</sl-button>
        </br>
        </br>
        <sl-button size="large" pill @click=${() => this.handleSignOut()}  variant="primary">Logout</sl-button>
        </br>
        </br>
        <sl-button size="large" pill href="${resolveRouterPath('home')}" variant="primary">Home Page</sl-button>
        </br>
        </br>
        UserType: ${globalThis.globalUserType}
        </br>
        ${buildNumber}
        </br>
        <sl-divider style="--color: red;"></sl-divider>

        <h2> Admin Tools </h2>

        <sl-switch id="suswitch"  @click=${() => this._switchUser()}  size="medium" ?checked=${adminChecked}  >Switch to admin user</sl-switch>


        </sl-drawer>








        <sl-button id="menu" pill class="menu-button">
        <sl-icon name="list"></sl-icon>
        </sl-button>




      </header>
    `: html ``;
  }


    //if this method throws an error if the user is not logged in or signed up.
    async currentAuthenticatedUser() {
      try {
        const { username, userId, signInDetails } = await getCurrentUser();
        console.log(`The username: ${username}`);
        console.log(`The userId: ${userId}`);
        console.log(`The signInDetails: ${signInDetails}`);
        this.loggedIn = true;
        let userEmailAddress = localStorage.getItem("emailAddress") as string;
        this.fetchUserProfileInfo(userEmailAddress);
      } catch (err) {
        const routePath=resolveRouterPath('login');
        router.navigate(routePath);


      }
    }

    async fetchUserProfileInfo(emailAddress:string){

     const client = generateClient();
     const variables = {
       filter:{
         emailAddress:{
           eq: emailAddress
         }
       }

     };
     const result = await client.graphql({
          query: queries.listUserProfiles,
          variables: variables
     });
     console.log(result);
      let responseDynmoDB_ID = (result.data.listUserProfiles!.items![0]?.id);

     globalThis.globalCurrentUserID = responseDynmoDB_ID as string;
     console.log("globalCurrentUserID", globalThis.globalCurrentUserID);
     // setUserType(result.data.listUserProfiles!.items![0]?.userType?.toString()!);
     globalThis.globalTargetGender = result.data.listUserProfiles!.items![0]?.targetGender as string;


      globalThis.incomingSwimmers = 0;
      const incomingSwimmersDataFromAPI = result.data.listUserProfiles!.items![0]?.incomingSwimmers?.length;
      //TODO why doesn't global this work?
      globalThis.incomingSwimmers = Number(incomingSwimmersDataFromAPI!);
      localStorage.setItem('incomingSwimmers', String(incomingSwimmersDataFromAPI!));

      globalThis.globalUserType = result.data.listUserProfiles!.items![0]?.userType as string;












     }


  async handleSignOut() {
    try {
      await signOut();
      console.log("signing out");
    } catch (error) {
      console.log('error signing out: ', error);
    }
    const drawer = this.shadowRoot?.querySelector('.drawer-placement-start') as SlDrawer
    drawer?.hide();
    const routePath=resolveRouterPath('login');
    router.navigate(routePath);

  }

/*
  _menuClicked(){
    console.log("menu clicked");


    if (this.menuOpen == false){
      this.menuOpen = true;
    } else{
      this.menuOpen = false;
    }

  }
  */
  _switchUser(){

    const adminswitch = this.shadowRoot?.querySelector('#suswitch') as SlSwitch
    console.log(adminswitch.checked);


    var adminString: string = String(adminswitch.checked);
   // localStorage.setItem("adminuser",adminString);
    var r = <HTMLElement>document.querySelector(':root');
    //var rs = getComputedStyle(r);
   //var s = rs.getPropertyValue('--lsheader');
   if (adminString == 'true'){
    r.style.setProperty('--lsheader', 'red');
    globalThis.globalIsAdminUser = true;
   }
   if (adminString == 'false'){
    r.style.setProperty('--lsheader', '#1E2952')
    globalThis.globalIsAdminUser = false;
   }



  }

}
