
    // Import the functions you need from the SDKs you need
    import { initializeApp } from "https://www.gstatic.com/firebasejs/10.14.0/firebase-app.js";
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyC80XsRrv7u7LtwOGmLSENf3h_yF3CwvqU",
      authDomain: "letssea-2aa73.firebaseapp.com",
      projectId: "letssea-2aa73",
      storageBucket: "letssea-2aa73.appspot.com",
      messagingSenderId: "894569302529",
      appId: "1:894569302529:web:d3c54a05ffbe441061ce00"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
  