import { css } from 'lit';



// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const styles = css`

h2{
  font-size: 2em;
  font-family: "DM Sans";
  color:black;
}
p {
  font-size: 23px;
  color:black;
  font-family: "DM Sans";
  text-wrap: balance;
}

section{
  position: relative;
  display:flex;
  align-items: center;
  width: 100%;
  height: 6vh;
  background: var(--lsheader);
  overflow: hidden;
}
.pageTitle{
  position: absolute;
  color: white;
  font-family: "DM Sans";
  font-size: 29px;
  left: 6%;
}

.floatingMenu{

    position:fixed;
    width: 271px;
    height:60px;
    bottom:85px;
    right:40px;
    font-family: "DM Sans";
    color:#FFF;
    border-radius:50px;
    text-align:center;


}


.cardo-regular {
  font-family: "Cardo", serif;
  font-weight: 400;
  font-style: normal;
}

.activityTitle{
  display: contents;
  font-size: 15px;
  font-weight: 400;
  color: black;
  margin-top: 15px;
  width: 100px;
  font-family: "DM Sans";
}
main{
 background: white;
 height:auto;
}

#resultsBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

  @media(min-width: 5000px) {
    sl-card {
      max-width: 70vw;
      background-color:black;
        padding: 18px;
        padding-top: 0px;
      }
    }
  }

  .alert-closeable{
    background-color;white;

  }
  .alert-closable::part(base){
    background-color: #BBD283;

  }

  .alert-closable::part(message){
    background-color:white;
    color:black;
    font-family: "DM Sans";
    font-size: 19px;

  }



  .avatar{
    display: flex;
    justify-content: center;
    background-color: #c0b2a4;


  }
  .avatar::part(base){
    height: 26vh;
    width: 33vw;

  }


  .avatar::part(image){
    object-fit: fill;
  }

  .avatar-button{
    padding: 0px;
    margin: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
   /* box-shadow: 10px 5px 5px black; */
    box-shadow: 5px 5px 5px 5px #1E2952;
  }

  .avatar-buttonSelected{
    padding: 0px;
    margin: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
   /* box-shadow: 10px 5px 5px black; */
    box-shadow: 5px 5px 5px 5px green;

  }




  sl-card::part(body){
   background:white;
   padding-left: 7px;
  }

  sl-card::part(base){
    border: none;
    box-shadow: none;
   }

  .result1{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
  }

  .resultsCardContainer{
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 10px

  }

  .checkMarkSelected{

    position: static;
    display: contents;
    top: -34px;
    left: 78%;
    width: fit-content;
    --sl-input-height-medium: 28px;
    --sl-input-border-width: 0px;
    z-index:99;
    border-radius: 50%;
    color: green;
    font-size: 1.425rem;
    transition: var(--sl-transition-medium) transform ease, var(--sl-transition-medium) border ease;
    box-shadow: none;
  }


  .borderMarkSelected{

    position: static;
    display: contents;
    top: -34px;
    left: 78%;
    width: fit-content;
    --sl-input-height-medium: 28px;
    --sl-input-border-width: 0px;
    z-index:99;
    border-radius: 50%;
    color: green;
    font-size: 1.425rem;
    transition: var(--sl-transition-medium) transform ease, var(--sl-transition-medium) border ease;
    box-shadow: none;
  }

  .partnersDiv{
    text-align:center;
  }
  sl-button.partnersButton::part(base) {
    position: relative;
    top: -31px;

    /* Set design tokens for height and border width */
    --sl-input-height-medium: 48px;
    --sl-input-border-width: 4px;
    z-index:99;
    border-radius: 50%;
    background:none;
    border:none;
    font-size: 1.125rem;
    transition: var(--sl-transition-medium) transform ease, var(--sl-transition-medium) border ease;


  }

.dialog-scrolling::part(panel){
  background-color:white;
}


  @media(min-width: 768px) {
    .resultsCardContainer{
      display: grid;
      grid-template-columns: auto auto auto;
      overflow: scroll;
    }

    .avatar::part(base) {
      height: 51vh;
      width: 20vw;
    }

    .checkMarkSelected{

      position: relative;
      top: -58px;
      left: 82%;
      width: fit-content;
      --sl-input-height-medium: 28px;
      --sl-input-border-width: 0px;
      z-index:99;
      border-radius: 50%;
      color: green;
      font-size: 2.425rem;
      transition: var(--sl-transition-medium) transform ease, var(--sl-transition-medium) border ease;
      box-shadow: none;
    }

    sl-button.partnersButton::part(base) {
      position: relative;
      top: 177px;
      right: 48px;

      /* Set design tokens for height and border width */
      --sl-input-height-medium: 48px;
      --sl-input-border-width: 4px;
      z-index:99;
      border-radius: 50%;
      background:none;
      border:none;
      font-size: 1.125rem;
      transition: var(--sl-transition-medium) transform ease, var(--sl-transition-medium) border ease;


    }


  }





  /* change the background color of the buttons*/
  sl-button::part(base){
    background-color: #1E2952;
    border-color:black;
    color:black;
    font-size: 20px;

  }

  sl-button::part(label){
    color:white;

  }



.checkMark{
  display:none;

}

.borderMark{
  display:none;

}





  sl-icon-button.yesActButton::part(base) {

    position: relative;
    top: -34px;
    left: 90px;

    --sl-input-height-medium: 28px;
    --sl-input-border-width: 0px;

    z-index:99;
    border-radius: 50%;
    color: green;
    font-size: 1.425rem;
    transition: var(--sl-transition-medium) transform ease, var(--sl-transition-medium) border ease;
  }



  sl-button.yesbutton::part(base) {
    /* Set design tokens for height and border width */
    --sl-input-height-medium: 48px;
    --sl-input-border-width: 4px;
    z-index:99;
    border-radius: 50%;
   /* background-color: #59ACDE;*/
    border-top-color: rgb(91, 124, 153);
    border-left-color: rgb(91, 124, 153);
    border-bottom-color: rgb(91, 124, 153);
    border-right-color: rgb(91, 124, 153);
    color: black;
    font-size: 1.125rem;
    box-shadow: 0 2px 10px #0002;
    transition: var(--sl-transition-medium) transform ease, var(--sl-transition-medium) border ease;
  }

  sl-button.nobutton::part(base) {
    /* Set design tokens for height and border width */
    --sl-input-height-medium: 48px;
    --sl-input-border-width: 4px;

    z-index:99;
    border-radius: 50%;
   /* background-color: #59ACDE;*/
    border-top-color: rgb(91, 124, 153);
    border-left-color: rgb(91, 124, 153);
    border-bottom-color: rgb(91, 124, 153);
    border-right-color: rgb(91, 124, 153);
    color: black;
    font-size: 1.125rem;
    box-shadow: 0 2px 10px #0002;
    transition: var(--sl-transition-medium) transform ease, var(--sl-transition-medium) border ease;
  }

  sl-button.notsurebutton::part(base) {
    /* Set design tokens for height and border width */
    --sl-input-height-medium: 48px;
    --sl-input-border-width: 4px;
    z-index:99;
    border-radius: 50%;
    /*background-color: #59ACDE;*/
    border-top-color: rgb(91, 124, 153);
    border-left-color: rgb(91, 124, 153);
    border-bottom-color: rgb(91, 124, 153);
    border-right-color: rgb(91, 124, 153);
    color: black;
    font-size: 1.125rem;
    box-shadow: 0 2px 10px #0002;
    transition: var(--sl-transition-medium) transform ease, var(--sl-transition-medium) border ease;
  }

  .confirmDialog{
    background-color:#5b7c99;
    justify-content: center;
    align-items: center;
    height: 100px;
    border: 3px solid black;
    position: relative;
    width: -webkit-fill-available;
    margin-top:10px;
    top: -150px;
  }

.resultscard{
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
}




`;